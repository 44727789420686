import React from 'react'

import { H, Wrapper } from 'components/common'
import Layout from 'components/layout'
import SEO from 'components/seo'
import VerticalCurve from 'components/VerticalCurve'

const NotFoundPage = () => (
  <Layout light>
    <SEO title="404: Not found" />
    <VerticalCurve>
      <Wrapper container centered>
        <H size="XL">404: Not found</H>
        <p>This page doesn’t exist.</p>
      </Wrapper>
    </VerticalCurve>
  </Layout>
)

export default NotFoundPage
