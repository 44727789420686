import styled from 'styled-components'

import verticalGreen from 'images/vectors/vertical-green-1.svg'

import { screenMin } from 'styles/helpers/responsive'
import { Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const VericalCurve = styled(Wrapper)`
  ${screenMin.l`
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -114px;
      bottom: 0;
      width: 30%;
      background-color: ${COLOR.ACCENT.PRIMARY};
      z-index: -2;
      
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -114px;
      bottom: 0;
      right: 0;
      width: 70%;
      z-index: -2;
      background-image: url('${verticalGreen}');
      background-repeat: no-repeat;
    }
  `}
`

export default VericalCurve
